import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A un kilomètre de la maison, le village de `}<a href="http://www.villedepegomas.fr/">{`Pégomas`}</a>{`
vous offrira tous les commerces et services nécessaires`}</p>
    <h2>{`Supermarchés`}</h2>
    <ul>
      <li parentName="ul">{`Intermarché,`}</li>
      <li parentName="ul">{`Super U.`}</li>
    </ul>
    <h2>{`Commerces de proximité`}</h2>
    <ul>
      <li parentName="ul">{`Deux boulangeries,`}</li>
      <li parentName="ul">{`boucherie - charcuterie, ( Boucherie ARMAND Viandes d’elevage de haute qualité )`}</li>
      <li parentName="ul">{`marchand de journaux - tabac,`}</li>
      <li parentName="ul">{`Fleuriste,`}</li>
      <li parentName="ul">{`clinique vétérinaire,`}</li>
      <li parentName="ul">{`bureau de Poste,`}</li>
      <li parentName="ul">{`Un marchand de légumes du pays.`}</li>
    </ul>
    <h2>{`Banques / distributeurs de billets`}</h2>
    <ul>
      <li parentName="ul">{`CREDIT AGRICOLE PROVENCE COTE D’AZUR Tél. 0821 08 06 40`}</li>
      <li parentName="ul">{`BNP PARIBAS Tél. 0820 820 001`}</li>
      <li parentName="ul">{`CAISSE D’EPARGNE Tél. 08 26 08 36 66`}</li>
    </ul>
    <h2>{`Medecins Généralistes`}</h2>
    <ul>
      <li parentName="ul">{`BAILLAT Charles - Tél. 04 93 42 22 82`}</li>
      <li parentName="ul">{`JACQUET Thierry - Tél. 04 93 42 24 57`}</li>
      <li parentName="ul">{`BODIN Jean-Claude - Tél. 04 93 42 27 84`}</li>
      <li parentName="ul">{`DROUOT Anne-Sophie - Tél. 04 93 42 27 84`}</li>
    </ul>
    <h2>{`Dentistes`}</h2>
    <ul>
      <li parentName="ul">{`MONIN Lydia - Tél.04 93 77 75 41`}</li>
      <li parentName="ul">{`CARABASSE Jean-Paul - Tél. 04 93 42 34 64`}</li>
      <li parentName="ul">{`LAIK Louis - Tél. 04 93 42 22 91`}</li>
      <li parentName="ul">{`NIDELET Jean-Louis - Tél. 04 93 42 24 02`}</li>
    </ul>
    <h2>{`Pharmacies`}</h2>
    <ul>
      <li parentName="ul">{`PHARMACIE BARRALIS - Tél. 04 93 42 22 13`}</li>
      <li parentName="ul">{`PHARMACIE DU CHATEAU - Tél. 04 93 42 39 42`}</li>
    </ul>
    <h2>{`Laboratoire d’analyses médicales`}</h2>
    <ul>
      <li parentName="ul">{`LABORATOIRE BARRALIS BERTHOMIEU - Tél. 04 93 42 39 11`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      